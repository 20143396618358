import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import { createI18n } from "vue-i18n";
import { messages, defaultLocale } from "@/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";
import moment from "moment";

const app = createApp(App);
app.config.globalProperties.$moment = moment;

app.use(store);
app.use(router);
app.use(ElementPlus as any);

if (!localStorage.locale) {
    localStorage.locale = defaultLocale;
}

const i18n = createI18n({
    legacy: false,
    messages,
    locale: localStorage.locale,
    fallbackLocale: localStorage.locale,
    globalInjection: true
});


i18n.global.locale = localStorage.locale;

app.use(i18n);

ApiService.init(app);
// initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.mount("#app");
