import { PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: '19466c48-1711-468c-99e1-978447c39637', //Client ID
    authority: 'https://login.microsoftonline.com/37cd273a-1cec-4aae-a297-41480ea54f8d', //Tenant ID
    redirectUri: 'https://arcelor-mittal.obea-ecoute-engagement.fr/auth/callback',
    postLogoutRedirectUri: "https://arcelor-mittal.obea-ecoute-engagement.fr/logout"
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
